import * as React from "react";
import { graphql } from "gatsby";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import { MarkdownRenderer } from "../common/Markdown";
import {
  SectionContainer,
  SectionWrapper
} from "../common/Section";

import { LegalHero } from "../components/Hero";


interface Props {
  data: any
};


const LegalTemplate: React.FC<Props> = ( props ) => {
  const {
    mdx: {
      body,
      frontmatter: {
        title,
        lastUpdated
      }
    }
  } = props.data;

  return (
    <Layout>
      <Head
        title={ title }
      />

      <SectionWrapper>
        <LegalHero
          heading={ title }
          lastUpdated={ lastUpdated }
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <MarkdownRenderer
            body={ body }
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export const query = graphql`
  query Legal($id: String) {
    mdx(id: {
      eq: $id
    }) {
      id
      body
      frontmatter {
        title
        lastUpdated(
          formatString: "MMMM Do, YYYY"
        )
      }
    }
  }
`;


export default LegalTemplate;
